import enterView from 'enter-view/enter-view';

const home = document.querySelector('.layout--home .section-main__inside');
if (home) {
    home.classList.add('animated');

    enterView({
        selector: '.layout--home .section-main__inside',
        enter: function(el) {
            el.classList.add('entered');
        },
        offset: 0,
        once: true,
    });
}
