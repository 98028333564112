import 'tiny-slider/dist/tiny-slider.css';
import { tns } from 'tiny-slider/src/tiny-slider';

const newsmodules = document.querySelectorAll('.mod_newslist, .mod_author_news, .mod_topic_news');
if (newsmodules.length) {
    for (const newslist of newsmodules) {
        const slider = tns({
            container: newslist,
            items: 1,
            gutter: 20,
            edgePadding: 0,
            controls: false,
            nav: false,
            loop: false,
            mouseDrag: true,
            freezable: false,
            preventScrollOnTouch: 'auto',
            responsive: {
                600: {
                    items: 2,
                },
                960: {
                    items: 3,
                    gutter: 0,
                    edgePadding: 20,
                },
                1900: {
                    items: 4,
                    gutter: 0,
                    edgePadding: 20,
                },
            },
        });

        const info = slider.getInfo();
        if (info.slideCount > info.items) {
            const swipeIcon = document.createElement('div');
            swipeIcon.classList.add('swipe-icon');
            swipeIcon.innerHTML = `
<svg class="swipe-icon__svg" xmlns="http://www.w3.org/2000/svg" width="30" height="25" viewBox="0 0 30 25">
    <path class="swipe-icon__right" d="M30,3.5,26.56,7l-.81-.82,2.06-2.1H22V2.92h5.81L25.74.82,26.55,0Z"/>
    <path class="swipe-icon__left" d="M8,4.08H2.19l2.06,2.1L3.44,7,0,3.5,3.45,0l.81.82L2.19,2.92H8Z"/>
    <path class="swipe-icon__hand" d="M25.41,11.18l-5.08-3A2.32,2.32,0,0,0,19,7.74a2.37,2.37,0,0,0-1.72.75v-5a2.46,2.46,0,0,0-.71-1.74A2.38,2.38,0,0,0,14.84,1h0a2.44,2.44,0,0,0-2.41,2.46V14.6l-.69-.7a4.7,4.7,0,0,0-3.36-1.42A4.75,4.75,0,0,0,6.32,13a1.26,1.26,0,0,0-.69.92A1.32,1.32,0,0,0,6,15l7.69,7.83A7.59,7.59,0,0,0,19,25a7.73,7.73,0,0,0,7.65-7.79V13.29A2.42,2.42,0,0,0,25.41,11.18Zm0,6A6.46,6.46,0,0,1,19,23.73h0a6.31,6.31,0,0,1-4.41-1.8L6.87,14.1a3.37,3.37,0,0,1,1.5-.35h0a3.42,3.42,0,0,1,2.47,1.05l1.53,1.55a.52.52,0,0,0,.36.16l.19,0a1.18,1.18,0,0,0,.75-1.1V3.46a1.17,1.17,0,0,1,1.16-1.19h0A1.18,1.18,0,0,1,16,3.46v6.91a.6.6,0,0,0,.39.56l.2,0a.58.58,0,0,0,.45-.22l1-1.3A1.16,1.16,0,0,1,19,9a1.12,1.12,0,0,1,.68.23l5.12,3a1.18,1.18,0,0,1,.59,1Z"/>
</svg>`;

            newslist.parentNode.insertBefore(swipeIcon, newslist);

            const hideSwipeIcon = () => {
                swipeIcon.parentNode.removeChild(swipeIcon);

                slider.events.off('transitionStart', hideSwipeIcon);
            };

            slider.events.on('transitionStart', hideSwipeIcon);
        }
    }
}
