let navigationOpen = false;
document.querySelector('.section-header__navigation-toggle').addEventListener('click', (e) => {
    e.preventDefault();

    if (navigationOpen) {
        document.body.classList.add('layout--overlay-close');
        setTimeout(() => {
            document.body.classList.remove('layout--overlay');
            document.body.classList.remove('layout--overlay-close');
        }, 500);
        navigationOpen = false;
    } else {
        document.body.classList.add('layout--overlay');
        navigationOpen = true;
    }
}, false);
